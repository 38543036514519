import React from 'react';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import Helmet from 'react-helmet'

const TestPage = () => {
  const {t} = useTranslation();
  return (
      <>
        <Layout>
        <Helmet>
        <title>Test title</title>
      </Helmet>
      <div id="main">
          <h1><Trans>QQ</Trans></h1>
      </div>
        </Layout>
      <h1>
        {t('QQ')}
      </h1>

    </>
  );
};

export default TestPage;